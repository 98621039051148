import { createStore } from 'vuex'

export default createStore({
  state: {
    cart: 0, // initial value of cart
    country: {
      id: 8,
      name: "المملكه العربيه السعوديه",
    },
  },
  getters: {},
  mutations: {
    increament(state) {
      state.cart += 1;
      localStorage.setItem('cart_num', state.cart)
    },
    decreament(state) {
      if (state.cart > 0) {
        state.cart -= 1;
        localStorage.setItem('cart_num', state.cart)
      }
    },
    setCountry(state, payload) {
      state.country.id = payload.id;
      state.country.name = payload.name;
    },
  },
  actions: {
    changeCountry({ commit }, country) {
      commit("setCountry", country);
    },
  },
  modules: {},
});
